import React from 'react';
import styled from 'styled-components';
import LogoCreative from 'assets/icons/logo_creative.svg';
import Grid from './containers/Grid';
import ProductionSlider from './ProductionSlider/ProductionSlider';


const subtitle ='Otwierasz nową markę, chcesz sfotografować kolekcję, ale nie wiesz od czego zacząć?';
const videoText = '<ul>Wieloletnia praca w świecie mody pozwoliła nam zebrać zespół niezbędnych osób do pracy przy produkcji:<li>sesji zdjęciowych</li><li>pokazów mody</li><li>planów reklamowych</li></ul>';

const StyledHero = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.black};
  padding: calc(3vh + 6rem) 1rem;
  h1 {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 40px;
    font-size: 4.6rem;
    letter-spacing: 8px;
    ${({ theme }) => theme.mq.s} {
      font-size: 3.2rem;
    }
    span {
      color: ${({ theme }) => theme.color.grayGreen};
    }
  }
  p {
    color: ${({ theme }) => theme.color.grayDark};
    letter-spacing: 2px;
    font-size: 1.8rem;
    line-height: 2.8rem;
    text-align: center;
    max-width: ${({ theme }) => theme.container.xs}px;
    ${({ theme }) => theme.mq.s} {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
`;

const StyledVideo = styled.video`
  max-width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  
  &:focus {
    outline: none;
  }
`;

const StyledLogoCreative = styled(LogoCreative)`
  height: 65px;
  display: block;
  margin: 0 auto 30px auto;
`;

const StyledVideoText = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-size: 1.5rem;
  letter-spacing: 1px;
  ul {
    list-style-type: circle;
    max-width: ${({ theme }) => theme.container.xs}px;
    margin: 0 3rem;
  }
  li {
    margin: 1.5rem 0 0 3.5rem;
  }
`;

const StyledSliderWrapper = styled.section`
  margin: 40px auto;
`;

const ProductionPage = ({ slides }) => (
  <>
    <StyledHero>
      <h1>
        Produkc
        <span>ja</span>
      </h1>
      <p>{subtitle}</p>
    </StyledHero>
    <Grid cols="1fr 1fr" colsS="1fr" padding="40px 0" gap="40px">
      <StyledVideo autoPlay muted autobuffer controls width="600px">
        Przepraszamy ale Twoja przeglądarka nie potrafi odtwarzać filmów za pomocą HTML5.
        <br />
        <a href="/movie.mp4" download>Pobierz film mp4</a>
        <br />
        <a href="/movie.ogg" download>Pobierz film ogg</a>
        <source src="/movie.mp4" type="video/mp4" />
        <source src="/movie.ogg" type="video/mp4" />
      </StyledVideo>
      <div>
        <StyledLogoCreative />
        <StyledVideoText dangerouslySetInnerHTML={{ __html: videoText }} />
      </div>
    </Grid>
    <StyledSliderWrapper>
      <ProductionSlider slides={slides} />
    </StyledSliderWrapper>
  </>
);

export default ProductionPage;
