import React from 'react';
import Carousel from 'nuka-carousel';
import ProductionSlide from './ProductionSlide';

const config = {
  wrapAround: true,
  autoplay: true,
  autoplayInterval: 3000,
  renderCenterLeftControls: null,
  renderCenterRightControls: null,
  renderBottomCenterControls: null,
  easing: 'easeExpInOut',
};

const ProductionSlider = ({ slides }) => (
  <Carousel {...config}>
    {slides.map(slide => <ProductionSlide key={slide.id} {...slide} />)}
  </Carousel>
);

export default ProductionSlider;
