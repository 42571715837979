import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: ${({ theme, size }) => theme.container[size]}px;
  padding: 0 3rem;
  ${({ theme }) => theme.mq.m} { padding: 0 2rem };
  ${({ theme }) => theme.mq.s} { padding: 0 .75rem };
`;

const Container = ({ children, size }) => (
  <StyledContainer size={size}>
    {children}
  </StyledContainer>
);

Container.defaultProps = {
  size: 'l'
};

export default Container;
