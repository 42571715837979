import React, { useMemo } from 'react';
import Layout from 'components/layout/Layout';
import Container from 'components/containers/Container';
import ProductionPage from 'components/ProductionPage';
import { graphql } from 'gatsby';

const Production = ({ data, location }) => {
  const slides = useMemo(() => data.allDatoCmsProductionSlide.edges
    .sort((a, b) => (a.node.position - b.node.position))
    .map(({ node }) => {
      const { id, backgroundImage, content: contentArr } = node;
      const content = contentArr[0];
      return {
        id,
        imageUrl: backgroundImage.url,
        textContent: content,
      };
    }, []), [data.allDatoCmsProductionSlide.edges]);
  return (
    <Layout location={location}>
      <Container>
        <ProductionPage slides={slides} />
      </Container>
    </Layout>
  );
};

export default Production;

export const query = graphql`
  query AllProductionSlides {
    allDatoCmsProductionSlide {
      edges {
        node {
          id
          position
          backgroundImage {
            url
          }
          content {
            main
            minor
            color {
              hex
            }
            textPosition
          }
        }
      }
    }
  }
`;
