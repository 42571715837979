import React from 'react';
import styled from 'styled-components';
import { hexToRgba } from '../../styles/theme';

const StyledSlideWrapper = styled.article`
  min-height: calc(100vh - ${({ theme }) => theme.navbarHeight.l}px);
  max-width: ${({ theme }) => theme.container.l}px;
  margin: 0 auto;
  background: url(${({ imageUrl }) => imageUrl}) no-repeat top center;
  background-size: cover;
  position: relative;
  ${({ theme }) => theme.mq.s} {
    min-height: calc(100vh - ${({ theme }) => theme.navbarHeight.s}px);
    padding: 0;
    background-position: top left;
  }
`;

const StyledContent = styled.section`
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  ${({ theme }) => theme.mq.m} {
    width: 40%;
  }
  ${({ theme }) => theme.mq.s} {
    box-sizing: border-box;
    width: 86%;
    height: auto;
    top: 50%;
    left: 7%;
    transform: translateY(-50%);
    padding: 30px 10px;
    background-color: ${({ color }) => hexToRgba(color, 0.8)};
  }
  h3 {
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: 3px;
    margin-bottom: 60px;
    text-transform: uppercase;
    padding: 0 2rem;
    position: relative;
    ${({ theme }) => theme.mq.m} {
      font-size: 2.1rem;
      letter-spacing: 1px;
    }
    &:before {
      display: block;
      content: '';
      height: 1px;
      width: 4rem;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 3rem);
      background-color: ${({ theme }) => theme.color.black};
    }
  }
  p {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 0 2rem 2rem 2rem;
    max-width: 240px;
  }
`;

const ProductionSlide = ({
  textContent, imageUrl,
}) => (
  <StyledSlideWrapper imageUrl={imageUrl}>
    { textContent && (
      <StyledContent color={textContent.color.hex} position={textContent.textPosition}>
        <h3>{textContent.main}</h3>
        <div dangerouslySetInnerHTML={{ __html: `<div> ${textContent.minor} </div>` }} />
      </StyledContent>
    )}
  </StyledSlideWrapper>
);

ProductionSlide.defaultProps = {
  inverted: false,
};

export default ProductionSlide;
